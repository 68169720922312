.noChat {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 3em;
}

.noChat h5 {
    margin: 2em;
    text-align: center
}
