
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttonStyleLandingPage {
    margin: 0.5em;
}

.description {
    margin: 0 0.5em 5em 0.5em;
    padding: 0.2em;
    border-radius: 10px;
    background-color: rgb(234, 247, 255, 0.9)
}
