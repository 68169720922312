
.divWithBackground {
    margin: 0.5em;
    display: flex;
    align-items: flex-end;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position: center;
    padding-bottom: 5em;
}
