
.chatHistoryWrapper {
    border: 1px solid black;
    height: 95%;
    width: 100%;
    overflow: scroll;
}

.currentUser {
    width: 85%;
    background-color: cadetblue;
    display: inline-block;
    margin-left: 14%;
    margin-top: 10px;
}

.otherUser {
    width: 85%;
    background-color: aliceblue;
    display: inline-block;
    margin-top: 10px;
}
